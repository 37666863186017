import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import {Box} from '@mui/material';
import {Add} from '@mui/icons-material';
import ConfirmationDialog from '../Dialog/ConfirmationDialog';
import CustomButton from '../Common/CustomButton';
import TestCaseSet from './TestCaseSet';
import LoadingOverlay from '../Common/LoadingOverlay';

import { useSnackbar } from "../../contexts/CustomSnackbarContext";
import { updateTestCaseSetList } from "../../redux-store/testCaseSetReducers/testCaseSetSlice";
import { fetchTestCaseSetList, deleteTestCaseSet } from '../../redux-store/testCaseSetReducers/testCaseSetActions';
import { updateTestScenario } from '../../redux-store/testScenarioReducers/testScenarioActions';

const TestCaseSetList = function({ testScenarioId, getNewTestCase, onDelete }) {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const [expandedId, setExpandedId] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteTestCaseSetDetail, setDeleteTestCaseSetDetail] = useState("");
  const testCaseSetList = useSelector(state => (state.testCaseSet.testCaseSetList));
  const isLoading = useSelector(state => state.testCaseSet.isLoading);

  useEffect(() => {
    getAllTestCases();
  }, []);

  const getAllTestCases = async () => {
    const testCaseSetList = await dispatch(fetchTestCaseSetList({testScenarioId: testScenarioId}));
    if (testCaseSetList?.payload?.length === 0) {
        await dispatch(updateTestCaseSetList({testCaseSetList: [getNewTestCase()]}));
    }
  }

  const onAddNewTestCase = async () => {
    await dispatch(updateTestCaseSetList({testCaseSetList: [...testCaseSetList, getNewTestCase()]}));
  }

  const updateOrder = async (testCaseSetListUpdated) => {
    await dispatch(updateTestScenario({testScenarioId: testScenarioId, data: {test_case_sets_order: testCaseSetListUpdated.filter(item => !item.isNew).map(item => item.id)}}))
  }

  const updateList = async (testCaseSetListUpdated, orderChanged=false) => {
    await dispatch(updateTestCaseSetList({testCaseSetList: [...testCaseSetListUpdated]}));
    if (orderChanged)
      updateOrder(testCaseSetListUpdated);
  }

  const onExpand = (id) => {
    if (id !== expandedId) {
      setExpandedId(id);
    } else {
      setExpandedId("");
    }
  }

  const onClickDelete = (testCaseSet) => {
    setDeleteTestCaseSetDetail(testCaseSet);
    onToggleDeleteDialog();
  }

  const onDeleteConfirm = async () => {
    const testCaseSetIndex = testCaseSetList.findIndex(testCaseSet => testCaseSet.id === deleteTestCaseSetDetail.id);
    const testCaseSetListUpdated = [...testCaseSetList.slice(0, testCaseSetIndex), ...testCaseSetList.slice(testCaseSetIndex+1, testCaseSetList.length)]
    if (!deleteTestCaseSetDetail.isNew) {
      await dispatch(deleteTestCaseSet({testCaseSetId: deleteTestCaseSetDetail.id}));
    }
    await dispatch(updateTestCaseSetList({testCaseSetList: [...testCaseSetListUpdated]}));
    onToggleDeleteDialog();
    updateOrder(testCaseSetListUpdated);
    openSnackbar({message: "Test case deleted successfully.", severity: "success"});
    if (testCaseSetListUpdated.length === 0) {
      getAllTestCases();
    }
  }

  const onToggleDeleteDialog = () => {
      setDeleteDialogOpen(prevState => !prevState);
      if (deleteTestCaseSetDetail?.id)
        setDeleteTestCaseSetDetail({});
  }

  const onDragEnd = async (result) => {
    if(!result.destination) {
       return;
    }
    const draggedTestCase = testCaseSetList[result?.source?.index];
    const sourceIndex = result?.source?.index;
    const destinationIndex = result?.destination?.index;
    if (sourceIndex > destinationIndex) {
      const firstHalf = testCaseSetList.slice(0, result?.destination?.index);
      const secondHalf = testCaseSetList.slice(result?.destination?.index).filter(item => item.id !== result?.draggableId);
      updateList([...firstHalf, draggedTestCase, ...secondHalf], true);
    } else {
      const firstHalf = testCaseSetList.slice(0, result?.destination?.index + 1).filter(item => item.id !== result?.draggableId);
      const secondHalf = testCaseSetList.slice(result?.destination?.index + 1);
      updateList([...firstHalf, draggedTestCase, ...secondHalf], true);
    }
  }

  const renderConfirmDialog = () => {
    return (
      <ConfirmationDialog
          open={deleteDialogOpen}
          onClose={onToggleDeleteDialog}
          onConfirm={onDeleteConfirm}
          title="Confirm Delete"
          content="Are you sure you want to delete?"
      />
    )
  }

  const renderDragNDrop = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {testCaseSetList.map((testCaseSet, index) => (
                <Draggable
                  key={testCaseSet.id}
                  draggableId={testCaseSet.id}
                  index={index}
                  isDragDisabled={expandedId === testCaseSet.id}
                >
                  {(provided, snapshot) => (
                    <div>
                      <div
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                      >
                        <Box sx={{width: "100%", marginBottom: "16px"}} key={testCaseSet.id}>
                          <TestCaseSet key={testCaseSet.id} detail={testCaseSet} isExpanded={expandedId === testCaseSet.id} updateList={updateList}
                            onExpand={onExpand} onClickDelete={() => {onClickDelete(testCaseSet)}} />
                        </Box>
                      </div>
                      {provided.placeholder}
                    </div>
                   )}
                </Draggable>
               ))}
              {provided.placeholder}
            </div>
           )}
        </Droppable>
      </DragDropContext>
    )
  }

  let content = null;

  if (isLoading && testCaseSetList?.length === 0) {
    content = (
      <Box sx={{width: "100%", textAlign: "center", padding: "50px 32px"}}>
        <h3>Loading test cases...</h3>
      </Box>
    )
  }
  if (testCaseSetList?.length > 0) {
    content = (
      <div style={{paddingTop: "16px", paddingBottom: "150px"}}>
        {renderDragNDrop()}
        <CustomButton startIcon={<Add />} onClick={onAddNewTestCase}>Add New Test Case</CustomButton>
      </div>
    )
  }

  return (
    <Box sx={{px: 2}}>
      <h5 style={{color: "Black", fontWeight: "500"}}>Test Cases</h5>
      {content}
      {deleteDialogOpen && renderConfirmDialog()}
      <LoadingOverlay isLoading={isLoading} />
    </Box>
  )
}

export default TestCaseSetList;

import {
  AddCircleOutline as AddCircleOutlineIcon,
  AppRegistration as AppRegistrationIcon,
  Assistant as AssistantIcon,
  DataArray as DataArrayIcon,
  DataObject as DataObjectIcon,
  Dataset as DatasetIcon,
  Delete as DeleteIcon,
  FactCheckOutlined as FactCheckOutlinedIcon,
  Folder as FolderIcon,
  Functions as FunctionsIcon,
  GroupsOutlined as GroupsIcon,
  HomeOutlined as HomeOutlinedIcon,
  LayersOutlined as LayersOutlinedIcon,
  Public as PublicIcon,
  QueryBuilder as QueryBuilderIcon,
  QuestionAnswer as QuestionAnswerIcon,
  BorderColor as TestScenarioIcon,
  UnfoldMore as UnfoldMoreIcon,
  ViewList as ViewListIcon,
  Search as SearchIcon,
  Settings as SettingsIcon
} from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

// Assets
import './CustomSidebar.css';
import './index.css';

// Components
import BuildReportsPage from '../BuildReports/BuildReportsPage.jsx';
import CollectionSidebar from '../Collections/CollectionSidebar';
import CustomSnackbar from '../Common/CustomSnackbar';
import FloatingMenu from '../Common/FloatingMenu/FloatingMenu';
import ChatsPage from '../Chats/ChatsPage.jsx';
import DataSetPage from "../DataSets/DataSetPage.jsx";
import FunctionsPage from '../Functions/FunctionsPage.jsx';
import GuideModal from '../Guide/GuideModal.jsx';
import OrganisationScreen from '../Organisation';
import ProjectScreen from '../Project';
import PublicPage from '../PublicPage/PublicPage.jsx';
import QodexBot from '../QodexBot/QodexBot.jsx';
import SettingsPage from '../Settings/SettingsPage.jsx';
import TestPlanBuildPage from '../TestPlanBuilds/TestPlanBuildsPage.jsx';
import TestPlanPage from '../TestPlans/TestPlanPage.jsx';
import TestScenarioBuildPage from '../TestScenarioBuildLogs/TestScenarioBuildPage';
import TestScenariosPage from '../TestScenarios/TestScenariosPage';
import TestSuiteBuildsPage from '../TestSuiteBuilds/TestSuiteBuildsPage.jsx';
import TestSuitesPage from '../TestSuites/TestSuitesPage.jsx';
import VariableSetPage from '../VariableSet/VariableSetPage';
import NavigationDrawer from './NavigationDrawer.jsx';
import FAQPage from '../FAQs/FAQPage.jsx';
import QuestionDetail from '../FAQs/FAQDetail.jsx';

// Configurations
import { apiAgent } from '../../config/axiosConfig';
import { useSnackbar } from '../../contexts/CustomSnackbarContext';
import { useGuestUser } from '../../contexts/GuestUserContext';
import {
  deleteProjectMember,
  fetchProjectMembers,
  generateInviteLink,
  inviteByEmail
} from '../../redux-store/currentUserActions';
import { resetTabs } from '../../redux-store/tabSlice';
import ProfileScreen from '../Account/Profile';
import LoadingOverlay from '../Common/LoadingOverlay';
import { fetchOrganisations as fetchGlobalOrganisations } from 'redux-store/dashboardActions';
import { setSelectedProject, setSelectedOrganisation } from 'reducers/commonSlice.js';
import { userLogOut } from 'redux-store/currentUserSlice';

// TODO: Add a new menu item for Runs

const sideBarDropdownMap = {
  "data": ["variable-set", "data-sets", "js-functions"],
  "plan": ["test-scenarios", "test-suites", "test-plans"],
  "apiDocs": ["public-page", "search", "faqs"]
}

const DashboardScreen = ({user, selectedProject, selectedOrganisation, organisations}) => {
  // State and Hooks
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    collectionSlug,
    folderSlug,
    entitySlug,
    testScenarioId,
    testScenarioBuildId,
    variableSetId,
    testSuiteId,
    testSuiteBuildId,
    testPlanId,
    testPlanBuildId,
    dataSetId,
    chatId,
    faqSlug
  } = useParams();
  const { openSnackbar } = useSnackbar();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const { isGuestUser } = useGuestUser();

  const [showGuideModal, setShowGuideModal] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [isCollectionSidebar, setCollectionSidebar] = useState(true);
  const [orgId, setOrgId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOrgModalOpen, setIsOrgModalOpen] = useState(false);
  const [isProfileScreenDialogOpen, setIsProfileScreenDialogOpen] =
    useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(collectionSlug);
  const [selectedFolder, setSelectedFolder] = useState(folderSlug);
  const [selectedEntity, setSelectedEntity] = useState(entitySlug);
  const [selectedTestScenarioId, setSelectedTestScenarioId] =
    useState(testScenarioId);
  const [selectedTestScenarioBuildId, setSelectedTestScenarioBuildId] = useState(testScenarioBuildId);
  const [selectedVariableSetId, setSelectedVariableSetId] =
    useState(variableSetId);
  const [selectedTestSuiteId, setSelectedTestSuiteId] = useState(testSuiteId);
  const [selectedTestSuiteBuildId, setSelectedTestSuiteBuildId] = useState(testSuiteBuildId);
  const [selectedTestPlanId, setSelectedTestPlanId] = useState(testPlanId);
  const [selectedTestPlanBuildId, setSelectedTestPlanBuildId] =
    useState(testPlanBuildId);
  const [selectedDataSetId, setSelectedDataSetId] = useState(dataSetId);
  const [selectedChatId, setSelectedChatId] = useState(chatId);
  const [selectedFaqSlug, setSelectedFaqSlug] = useState(faqSlug);

  const [isInvitationModalOpen, setIsInvitationModalOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [invitationProjectId, setInvitationProjectId] = useState(null);
  const [emailError, setEmailError] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [emailSentMessage, setEmailSentMessage] = useState('');
  const [generatedLink, setGeneratedLink] = useState('');
  const [projectMembers, setProjectMembers] = useState([]);
  const [filter, setFilter] = useState('all');
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const projectSideBarRef = useRef(null);
  const orgModalRef = useRef(null);
  const projectModalRef = useRef(null);
  const profileModalRef = useRef(null);
  const initialMount = useRef(true);
  // const [expandedNodeIds, setExpandedNodeIds] = useState([]);
  // const []
  const eligibleForSidebar = [
    'collections',
    'members',
    'test-scenarios',
    'test-scenarios-build',
    'variable-set',
    'test-suites',
    'test-suite-builds',
    'test-plans',
    'test-plan-builds',
    'build-reports',
    'js-functions',
    'data-sets',
    'settings',
    'public-page',
    'search',
    'faqs'
  ];
  const customHeaderMenuItems = [
    'test-scenarios',
    'test-scenarios-build',
    'variable-set',
    'test-suites',
    'test-suite-builds',
    'test-plans',
    'test-plan-builds',
    'build-reports',
    'js-functions',
    'data-sets',
    'settings',
    'public-page',
    'search',
    'faqs'
  ]
  const getSelectedMenuItem = () => {
    if (history.location.pathname.includes('/members')) return 'members';
    if (history.location.pathname.includes('/test-scenarios') && history.location.pathname.includes('/build'))
      return 'test-scenarios-build';
    if (history.location.pathname.includes('/test-scenarios'))
      return 'test-scenarios';
    if (history.location.pathname.includes('/variable-set'))
      return 'variable-set';
    if (history.location.pathname.includes('/test-suites') && history.location.pathname.includes('/build'))
      return 'test-suite-builds';
    if (history.location.pathname.includes('/test-suites'))
      return 'test-suites';
    if (history.location.pathname.includes('/test-plans') && history.location.pathname.includes('/build'))
      return "test-plan-builds";
    if (history.location.pathname.includes('/test-plans'))
      return 'test-plans';
    if (history.location.pathname.includes('/build-reports'))
      return 'build-reports';
    if (history.location.pathname.includes('/js-functions'))
      return 'js-functions';
    if (history.location.pathname.includes('/data-sets'))
      return 'data-sets';
    if (history.location.pathname.includes('/settings'))
      return 'settings';
    if (history.location.pathname.includes('/public-page'))
      return 'public-page';
    if (history.location.pathname.includes('/search'))
      return 'search';
    if (history.location.pathname.includes('/faqs'))
      return 'faqs';
    return 'collections';
  };
  const menuItems = [
    {
      label: 'Collections',
      id: 'collections',
      icon: <LayersOutlinedIcon fontSize="large" />,
      onClick: () => {
        handleTabClick('collections');
        handleCollectionSidebar('collections');
        history.push('/collections');
      },
      href: "/collections"
    },
    {
      label: 'Data',
      id: 'data',
      icon: <DataArrayIcon fontSize='large' sx={{width: "20px", height: "20px"}} />,
      type: 'dropdown',
      items: [
        {
          label: 'Variable Set',
          id: 'variable-set',
          icon: <DataObjectIcon fontSize="large" />,
          onClick: () => {
            handleTabClick('variable-set');
            handleCollectionSidebar('variable-set');
            history.push(`/variable-set`);
          },
          href: "/variable-set",
          parentItemId: 'data'
        },
        {
          label: 'Data Sets',
          id: 'data-sets',
          icon: <DatasetIcon fontSize="large" />,
          onClick: () => {
            handleTabClick('data-sets');
            handleCollectionSidebar('data-sets');
            history.push(`/data-sets`);
          },
          href: "/data-sets",
          parentItemId: 'data'
        },
        {
          label: 'JS Functions',
          id: 'js-functions',
          icon: <FunctionsIcon fontSize="large" />,
          onClick: () => {
            handleTabClick('js-functions');
            handleCollectionSidebar('js-functions');
            history.push(`/js-functions`);
          },
          href: "/js-functions",
          parentItemId: 'data'
        }
      ]
    },
    {
      label: 'Schedule test',
      id: 'plan',
      icon: <AppRegistrationIcon fontSize='large' sx={{width: "20px", height: "20px"}} />,
      type: 'dropdown',
      items: [
        {
          label: 'Test Scenarios',
          id: 'test-scenarios',
          icon: <TestScenarioIcon fontSize="large" />,
          onClick: () => {
            handleTabClick('test-scenarios');
            handleCollectionSidebar('test-scenarios');
            history.push('/test-scenarios');
          },
          href: "/test-scenarios",
          parentItemId: 'plan'
        },
        {
          label: 'Test Suites',
          id: 'test-suites',
          icon: <ViewListIcon fontSize="large" />,
          onClick: () => {
            history.push(`/test-suites/`);
            handleCollectionSidebar('test-suites');
            handleTabClick('test-suites');
          },
          href: "/test-suites",
          parentItemId: 'plan'
        },
        {
          label: 'Test Plans',
          id: 'test-plans',
          icon: <QueryBuilderIcon fontSize="large" />,
          onClick: () => {
            handleTabClick('test-plans');
            handleCollectionSidebar('test-plans');
            history.push(`/test-plans/`);
          },
          href: "/test-plans",
          parentItemId: 'plan'
        }
      ]
    },
    {
      label: 'API docs',
      id: 'apiDocs',
      icon: <PublicIcon fontSize='large' sx={{width: "20px", height: "20px"}} />,
      type: 'dropdown',
      items: [
        {
          label: 'Settings',
          id: 'public-page',
          icon: <SettingsIcon fontSize="large" />,
          onClick: () => {
            handleTabClick('public-page');
            handleCollectionSidebar('public-page');
            history.push('/public-page');
          },
          href: "/public-page",
          parentItemId: 'apiDocs'
        },
        {
          label: 'Search',
          id: 'search',
          icon: <SearchIcon fontSize="large" />,
          onClick: () => {
            handleTabClick('search');
            handleCollectionSidebar('search');
            history.push('/search');
          },
          href: "/search",
          parentItemId: "apiDocs"
        },
        {
          label: 'FAQs',
          id: 'faqs',
          icon: <QuestionAnswerIcon fontSize="large" />,
          onClick: () => {
            handleTabClick('faqs');
            handleCollectionSidebar('faqs');
            history.push('/faqs');
          },
          href: "/faqs",
          parentItemId: "apiDocs"
        },
      ]
    },
    {
      label: 'Build Reports',
      id: 'build-reports',
      icon: <FactCheckOutlinedIcon fontSize="large" />,
      onClick: () => {
        handleTabClick('build-reports');
        handleCollectionSidebar('build-reports');
        history.push(`/build-reports`);
      },
      href: "/build-reports"
    },
    {
      label: 'Team Members',
      id: 'members',
      icon: <GroupsIcon fontSize="large" />,
      onClick: () => {
        handleTabClick('members');
        handleCollectionSidebar('members');
        history.push('/members');
      },
      href: "/members"
    },
  ];

  const [selectedMenuItem, setSelectedMenuItem] = useState(
    getSelectedMenuItem()
  );

  useEffect(() => {
    const initComponent = async () => {
      if (!organisations || organisations.length == 0){
        await dispatch(fetchGlobalOrganisations({}));
      }

      if (initialMount.current) {
        initTwitterTracking();
        initRedditTracking();
        initialMount.current = false;
      }

      function handleClickOutside(event) {
        if (orgModalRef.current && !orgModalRef.current.contains(event.target)) {
          setIsOrgModalOpen(false);
        }
        if (
          projectModalRef.current &&
          !projectModalRef.current.contains(event.target)
        ) {
          setIsModalOpen(false);
        }
        if (
          profileModalRef.current &&
          !profileModalRef.current.contains(event.target)
        ) {
          setIsProfileScreenDialogOpen(false);
        }
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setDropdownVisible(false);
        }
        if (
          projectSideBarRef.current &&
          !projectSideBarRef.current.contains(event.target)
        ) {
          closeSidebar();
        }
      }

      validateShowGuideModal();
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    };

    // Initialize the component
    initComponent();
  }, [dispatch]);



  useEffect(() => {
    if (selectedProject) {
      fetchMembers();
      const selectedOrg = organisations.filter(org => org?.id === selectedProject?.organisation_id);
      if (selectedOrg?.[0]) {
        dispatch(setSelectedOrganisation({...selectedOrg[0]}));
      }
    }
  }, [selectedProject]);

  useEffect(() => {
    const selectedMenuItem = getSelectedMenuItem();
    setSelectedMenuItem(selectedMenuItem);
  }, [history.location.pathname]);

  useEffect(() => {
    validateShowGuideModal();
  }, [history.location.search])

  useEffect(() => {
    // TODO :: What if the collection slug is not valid?
    if (collectionSlug) {
      setSelectedCollection(collectionSlug);
    }

    if (folderSlug) {
      setSelectedFolder(folderSlug);
    }

    if (entitySlug) {
      setSelectedEntity(entitySlug);
    }
  }, [collectionSlug, folderSlug, entitySlug]);

  useEffect(() => {
    if (testScenarioId) {
      setSelectedTestScenarioId(testScenarioId);
    }
  }, [testScenarioId]);

  useEffect(() => {
    if (testScenarioBuildId) {
      setSelectedTestScenarioBuildId(testScenarioBuildId);
    }
  }, [testScenarioBuildId]);

  useEffect(() => {
    if (variableSetId) {
      setSelectedVariableSetId(variableSetId);
    }
  }, [variableSetId]);

  useEffect(() => {
    if (testSuiteId) {
      setSelectedTestSuiteId(testSuiteId);
    }
  }, [testSuiteId]);

  useEffect(() => {
    if (testSuiteBuildId) {
      setSelectedTestSuiteBuildId(testSuiteBuildId);
    }
  }, [testSuiteBuildId]);

  useEffect(() => {
    if (testPlanId) {
      setSelectedTestPlanId(testPlanId);
    }
  }, [testPlanId]);

  useEffect(() => {
    if (testPlanBuildId) {
      setSelectedTestPlanBuildId(testPlanBuildId);
    }
  }, [testPlanBuildId]);

  useEffect(() => {
    if (dataSetId) {
      setSelectedDataSetId(dataSetId);
    }
  }, [dataSetId]);

  useEffect(() => {
    if (chatId) {
      setSelectedChatId(chatId);
    }
  }, [chatId]);

  useEffect(() => {
    if (faqSlug) {
      setSelectedFaqSlug(faqSlug);
    }
  }, [faqSlug]);

  // Functions and Handlers
  // Set the initial menu item based on the current route

  const initTwitterTracking = function() {
    if (window.twq) {
      window.twq('config','on7ou');
      window.twq('track', 'collection-view');
    }
  }

  const initRedditTracking = function() {
    if (window.rdt) {
      window.rdt('init','a2_fjjoqyuwczjo');
      window.rdt('track', 'PageVisit');
    }
  }

  const validateShowGuideModal = () => {
    if (history.location.search.indexOf("showGuide") !== -1) {
      setShowGuideModal(true);
      const currentLocation = history.location.pathname + history.location.search.replace("showGuide", "");
      history.replace(currentLocation);
    }
  }

  const closeSidebar = () => {
    setSidebarVisible(false);
  };

  const fetchMembers = async () => {
    setIsLoading(true);
    const res = await dispatch(fetchProjectMembers(selectedProject.id));
    if (res.payload) {
      setProjectMembers([...res.payload] || []);
    }
    setIsLoading(false);
  };

  const openModal = (org) => {
    setSidebarVisible(false);
    setIsModalOpen(true);
    setOrgId(org);
  };

  const openModalOrg = () => {
    setSidebarVisible(false);
    setIsOrgModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsOrgModalOpen(false);
    fetchOrganisations();
  };

  const selectDefaultProject = async () => {
    if(selectedProject?.id) return;
    const lastProject = JSON.parse(localStorage.getItem('lastSelectedProject'));

    if (!lastProject && organisations.length > 0 && organisations[0].projects.length > 0) {
      const firstProject = organisations[0].projects[0];
      dispatch(setSelectedProject(firstProject));
      localStorage.setItem('lastSelectedProject', JSON.stringify(firstProject));
    } else {
      dispatch(setSelectedProject(lastProject));
    }
  }

  useEffect(() => {
    selectDefaultProject();
  }, [organisations]);

  const fetchOrganisations = async () => {
    await dispatch(fetchGlobalOrganisations({}));
  };

  const filteredMembers = projectMembers.filter((member) => {
    switch (filter) {
      case 'joined':
        return member.status === 'joined';
      case 'pending':
        return member.status === 'pending';
      case 'all':
      default:
        return true;
    }
  });

  const handleTabClick = (tab) => {
    setSidebarVisible(false);
    if (eligibleForSidebar.indexOf(tab) !== -1) {
      setCollectionSidebar(true);
    } else {
      setCollectionSidebar(false);
    }
    // setExpandedNodeIds([])
    setSelectedMenuItem(tab);
  };

  const handleProjectClick = (project, queryParams="") => {
    dispatch(resetTabs());
    dispatch(setSelectedProject(project));
    const selectedOrg = organisations.filter(org => org?.id === project?.organisation_id);
    if (selectedOrg?.length?.[0]) {
      dispatch(setSelectedOrganisation({...selectedOrg[0]}));
    }
    history.push(`/collections?${queryParams}`);
    localStorage.setItem('lastSelectedProject', JSON.stringify(project));
    closeSidebar();
  };

  const updateSelectedProject = (project) => {
    handleProjectClick(project, "showGuide=1");
  };

  const handleSelectClick = useCallback(() => {
    setSidebarVisible((prevSidebarVisible) => !prevSidebarVisible);
  }, []);

  const handleCollectionSidebar = (checkMenuItem) => {
    if (selectedMenuItem !== checkMenuItem)
      setCollectionSidebar((prevIsCollectionSidebar) => !prevIsCollectionSidebar);
  };

  const handleLogout = async () => {
    localStorage.clear();
    await dispatch(userLogOut());
    history.push('/login', { showEmailSentMessage: false });
  };

  const openInvitationModal = (projectId) => {
    setInvitationProjectId(projectId);
    setIsInvitationModalOpen(true);
  };

  const closeInvitationModal = () => {
    setIsInvitationModalOpen(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._+\-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const handleInviteByEmail = async () => {
    if (!validateEmail(inviteEmail)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    setEmailError('');
    setIsSending(true);

    const res = await dispatch(
      inviteByEmail({ email: inviteEmail, projectId: invitationProjectId })
    );
    if (res.payload && res.payload.message === 'Invitation sent successfully') {
      fetchMembers();
      setInviteEmail('');
      openSnackbar({ message: "Invitation sent successfully!", severity: 'success' });
      setIsInvitationModalOpen(false);
    } else {
      openSnackbar({ message: res?.payload || 'Failed to send the invitation. Please try again.', severity: 'error' });
    }

    setIsSending(false);
  };

  const onProjectUpdate = (project) => {
    dispatch(setSelectedProject(project));
    localStorage.setItem(
      'lastSelectedProject',
      JSON.stringify(project)
    );
  }

  const handleGenerateInviteLink = async () => {
    setIsSending(true);

    const res = await dispatch(generateInviteLink(invitationProjectId));
    if (res.payload && res.payload.link) {
      setGeneratedLink(res.payload.link);
    } else {
      setEmailSentMessage(
        'Failed to generate the invite link. Please try again.'
      );
    }

    setIsSending(false);
  };

  const confirmDeleteMember = async () => {
    const response = await dispatch(
      deleteProjectMember({
        projectId: selectedProject.id,
        memberId: selectedMemberId,
      })
    );

    if (response?.payload?.message) {
      openSnackbar({ message: response.payload.message, severity: 'success' });
      fetchMembers();
    } else if (response?.payload?.error) {
      openSnackbar({ message: response.payload.error, severity: 'error' });
    }

    setOpenDeleteConfirmation(false);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleProfileClick = () => {
    setDropdownVisible(false);
    toggleProfileScreenDialog();
  };

  const toggleProfileScreenDialog = () => {
    setIsProfileScreenDialogOpen(!isProfileScreenDialogOpen);
  };

  const handleSettingsClick = () => {
    setDropdownVisible(false);
    history.push("/settings/");
  }

  const CustomDropdown = () => (
    <Box
      ref={dropdownRef}
      sx={{
        position: 'absolute',
        bottom: "10px",
        left: "240px",
        zIndex: 9,
        mt: '2px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        backgroundColor: '#fff',
        minWidth: '120px',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Box
        sx={{
          padding: '8px 0',
        }}
      >
        {/* <Button
          sx={{ justifyContent: 'flex-start' }}
          fullWidth
          onClick={handleProfileClick}
        >
          Profile
        </Button> */}
        <Button
          sx={{ justifyContent: 'flex-start' }}
          fullWidth
          onClick={handleSettingsClick}
        >
          Settings
        </Button>
        <Button
          sx={{ justifyContent: 'flex-start' }}
          fullWidth
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Box>
    </Box>
  );

  const RenderToolbar = () => {
    if (customHeaderMenuItems.indexOf(selectedMenuItem) === -1) {
      let title;
      let subtitleText;
      if (selectedMenuItem === "dashboard") {
        title = "Dashboard";
      } else if (selectedMenuItem === "collections") {
        title = "Collections";
        subtitleText = "Create/edit collections, entities"
      } else if (selectedMenuItem === "members") {
        title = "Team Members";
        subtitleText = "Invite/remove team members"
      }
      return (
        <Box className="toolbar-container" sx={{borderBottom: "none", height: "100px", position: "relative", zIndex: 10, overflow: "hidden", boxShadow: "0px 0px 2px 0.5px #4d4d4d"}}>
          <Toolbar variant="dense" className="tool-bar-style" sx={{zIndex: 2, height: "100%"}}>
            <Grid container direction="column" justifyContent="center" sx={{padding: "8px 20px"}}>
              <Grid item sx={{padding: "14px 0px"}}>
                <h4 style={{ margin: '0px', color: 'black', fontSize: '24px'  }}>
                  {title}
                </h4>
                <Typography variant="body1" color="gray" className="truncate-1-lines">
                  {subtitleText}
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </Box>
      )
    };
  };

  const RenderTeamMembers = () => {
    return (
      <div style={{ width: '100%', padding: "40px" }}>
        {/* <Typography variant="h5">Team Members</Typography> */}
        <Box display="flex" justifyContent="space-between" my={2}>
          <div>
            <Button
              variant={filter === 'all' ? 'contained' : 'outlined'}
              onClick={() => setFilter('all')}
            >
              All
            </Button>
            <Button
              variant={filter === 'joined' ? 'contained' : 'outlined'}
              style={{ marginLeft: '10px' }}
              onClick={() => setFilter('joined')}
            >
              Joined Users
            </Button>
            <Button
              variant={filter === 'pending' ? 'contained' : 'outlined'}
              style={{ marginLeft: '10px' }}
              onClick={() => setFilter('pending')}
            >
              Pending Users
            </Button>
          </div>
          {(selectedProject?.is_owner || true) && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => openInvitationModal(selectedProject.id)}
            >
              Invite New Member
            </Button>
          )}
        </Box>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredMembers.map((member) => (
              <tr key={member.id}>
                <td>{member.username || ''}</td>
                <td>{member.email}</td>
                <td>{member.is_owner ? "Admin" : "Member"}</td>
                <td>{member.status || ''}</td>
                <td>
                  <IconButton
                    onClick={() => {
                      setSelectedMemberId(member.id);
                      setOpenDeleteConfirmation(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Typography variant="body1">
          Total Members: {filteredMembers.length}
        </Typography>
        <LoadingOverlay open={isLoading} />
        <Dialog
          open={openDeleteConfirmation}
          onClose={() => setOpenDeleteConfirmation(false)}
          aria-labelledby="confirmation-dialog-title"
          aria-describedby="confirmation-dialog-description"
        >
          <DialogTitle id="confirmation-dialog-title">Confirm Fix</DialogTitle>
          <DialogContent>
            <DialogContentText id="confirmation-dialog-description">
              Are you sure you want to delete this member from the project?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenDeleteConfirmation(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={confirmDeleteMember} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const getProjectButton = useMemo(() => (
    <Button
      variant="contained"
      size="large"
      className="selectProjectButton"
      onClick={handleSelectClick}
    >
      <div className="project">
        <span className="projectName truncate-1-lines" title={selectedProject?.name}>{selectedProject?.name}</span>
        <span className="orgName truncate-1-lines" title={selectedOrganisation?.name}>{selectedOrganisation?.name}</span>
      </div>
      <span className="icon-button" id="projects">
        <UnfoldMoreIcon />
      </span>
    </Button>
  ), [selectedProject?.id, selectedOrganisation?.id]);

  const isMenuItemDisabled = useCallback((itemId) => {
    const allowedItems = [...eligibleForSidebar];
    return !allowedItems.includes(itemId);
  }, []);

  const parentNode = useMemo(() => {
    const parents = Object.keys(sideBarDropdownMap);
    let parentId;
    for (let i = 0; i < parents.length; i++) {
      if (sideBarDropdownMap[parents[i]].indexOf(selectedMenuItem) !== -1) {
        parentId = parents[i];
        break;
      }
    }
    return parentId ? [parentId] : [];
  })


  const RenderSidebar = useCallback(() => {
    return (
      <>
        <NavigationDrawer
          isItemDisabled={isMenuItemDisabled}
          drawerItems={[menuItems]}
          selectedMenuItem={selectedMenuItem}
          onProfileClick={toggleDropdown}
          profileDropdownRef={dropdownRef}
          projectSideBarRef={projectSideBarRef}
          onProjectSelect={handleSelectClick}
          getProjectButton={getProjectButton}
          selectedNodeIdInitial={selectedMenuItem}
          expandedNodeIdsInitial={parentNode}
        />
        {dropdownVisible && <CustomDropdown />}
      </>
    )
  }, [selectedMenuItem, selectedProject, selectedOrganisation?.id, dropdownVisible]);

  const ProjectSidebar = () => {
    return (
      <Box
        ref={projectSideBarRef}
        sx={{
          width: "240px",
          bgcolor: 'background.paper',
          position: 'fixed',
          top: 80,
          left: 10,
          zIndex: 1200,
          boxShadow: 3,
          overflowY: 'auto',
          paddingBottom: '20px',
          borderRadius: "10px"
        }}
      >
        <Box
          sx={{
            p: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6" noWrap>
            Your Organisations
          </Typography>
          <Tooltip title="Add Organisation" placement="top-start">
            <IconButton
              color="primary"
              onClick={openModalOrg}
              aria-label="add organisation"
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Divider />
        <Box sx={{maxHeight: "calc(100vh - 300px)", overflowY: 'auto'}}>
          {organisations.map((org) => (
            <Box key={org.id} sx={{ borderTop: 1, borderColor: 'divider' }}>
              <List component="nav" aria-label="organisation projects">
                <ListItem>
                  <Tooltip title={org.name.toUpperCase()} placement="top-start">
                    <ListItemText
                      className="truncate-1-lines"
                      primary={org.name.toUpperCase()}
                      primaryTypographyProps={{ fontWeight: 'bold' }}
                    />
                  </Tooltip>
                  {org.is_owner && (
                    <Tooltip title="Add Project" placement="top-start">
                      <IconButton
                        color="primary"
                        aria-label="add project"
                        onClick={() => openModal(org)}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </ListItem>
                {org.projects.map((project) => (
                  <ListItem
                    button
                    key={project.id}
                    onClick={() => handleProjectClick(project)}
                    sx={{
                      backgroundColor:
                        selectedProject && selectedProject.id === project.id
                          ? '#f5f5f5'
                          : 'inherit',
                    }}
                  >
                    <ListItemIcon>
                      <FolderIcon fontSize="small" />
                    </ListItemIcon>
                    <Tooltip title={project.name} placement="top-start">
                      <ListItemText className="truncate-1-lines" primary={project.name} />
                    </Tooltip>
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box
        className="main-content-container"
        id="app"
        style={{ height: "100vh", display: "flex", backgroundColor: "#fff" }}
      >
        <RenderSidebar />

        <Box
          sx={{ flexGrow: 1 }}
          style={{ maxWidth: "calc(100% - 200px)" }}
        >
          <div>
            <RenderToolbar />

            {selectedMenuItem === "members" && <RenderTeamMembers />}

            {selectedMenuItem === "collections" && selectedProject && (
              <CollectionSidebar />
            )}
            {selectedMenuItem === "test-scenarios" && selectedProject && (
              <TestScenariosPage
                testScenarioId={selectedTestScenarioId}
                buildId={selectedTestScenarioBuildId}
              />
            )}
            {selectedMenuItem === "test-scenarios-build" && selectedProject && (
              <TestScenarioBuildPage
                project={selectedProject}
                testScenarioId={selectedTestScenarioId}
                buildId={selectedTestScenarioBuildId}
              />
            )}
            {selectedMenuItem === "variable-set" && selectedProject && (
              <VariableSetPage
                project={selectedProject}
                variableSetId={selectedVariableSetId}
              />
            )}
            {selectedMenuItem === "test-suites" && selectedProject && (
              <TestSuitesPage
                project={selectedProject}
                testSuiteId={selectedTestSuiteId}
              />
            )}
            {selectedMenuItem === "test-suite-builds" && selectedProject && (
              <TestSuiteBuildsPage
                project={selectedProject}
                testSuiteId={selectedTestSuiteId}
                buildId={selectedTestSuiteBuildId}
              />
            )}
            {selectedMenuItem === "test-plans" && selectedProject && (
              <TestPlanPage
                project={selectedProject}
                testPlanId={selectedTestPlanId}
              />
            )}
            {selectedMenuItem === "test-plan-builds" && selectedProject && (
              <TestPlanBuildPage
                project={selectedProject}
                testPlanId={selectedTestPlanId}
                buildId={selectedTestPlanBuildId}
              />
            )}
            {selectedMenuItem === "build-reports" && selectedProject && (
              <BuildReportsPage
                projectId={selectedProject?.id}
              />
            )}
            {selectedMenuItem === "js-functions" && selectedProject && (
              <FunctionsPage
                projectId={selectedProject?.id}
              />
            )}
            {selectedMenuItem === "data-sets" && selectedProject && (
              <DataSetPage
                projectId={selectedProject?.id}
                dataSetId={selectedDataSetId}
              />
            )}
            {selectedMenuItem === "settings" && selectedProject && (
              <SettingsPage
                onOrganisationUpdate={fetchOrganisations}
                onProjectUpdate={onProjectUpdate}
              />
            )}
            {selectedMenuItem === "public-page" && selectedProject && (
              <PublicPage
                project={selectedProject}
                onUpdate={onProjectUpdate}
              />
            )}
            {selectedMenuItem === "search" && selectedProject && (
              <ChatsPage
                project={selectedProject}
                chatId={selectedChatId}
              />
            )}
            {selectedMenuItem === "faqs" && selectedProject && (
              <FAQPage
                project={selectedProject}
                faqSlug={selectedFaqSlug}
              />
            )}
            {sidebarVisible && <ProjectSidebar />}
          </div>

          {isModalOpen && (
            <>
              <Box className="modal-overlay" ref={projectModalRef}>
                <ProjectScreen
                  org={orgId}
                  onChild={closeModal}
                  updateSelectedProject={updateSelectedProject}
                />
              </Box>
            </>
          )}
          {isOrgModalOpen && (
            <>
              <Box className="modal-overlay" ref={orgModalRef}>
                <OrganisationScreen onChild={closeModal} />
              </Box>
            </>
          )}
          {isProfileScreenDialogOpen && (
            <>
              <Box className="modal-overlay" ref={profileModalRef}>
                <ProfileScreen
                  user={user}
                  onChild={toggleProfileScreenDialog}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>

      <Dialog
        open={isInvitationModalOpen}
        onClose={isSending ? null : closeInvitationModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Invite New Member</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To invite a user, please enter their email address here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
            error={!!emailError}
            helperText={emailError || emailSentMessage}
            disabled={isSending}
          />
          {generatedLink && (
            <Typography variant="body2">
              Invite Link: {generatedLink}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleInviteByEmail}
            color="primary"
            disabled={isSending}
          >
            Invite by Email
          </Button>
          {/* <Button
            onClick={handleGenerateInviteLink}
            color="primary"
            disabled={isSending}
          >
            Generate Invite Link
          </Button> */}
          <Button
            onClick={closeInvitationModal}
            color="secondary"
            disabled={isSending}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={snackbarOpen}
        handleClose={() => {
          setSnackbarOpen(false);
        }}
        message="API Key copied to clipboard"
        severity="success"
      />
      <FloatingMenu
        icon={<AssistantIcon size="large" />}
        renderMenu={(props) =>
          (<QodexBot {...{...props, projectId: selectedProject?.id }} showGuideModal={setShowGuideModal} />)}
      />
      <GuideModal open={showGuideModal} onClose={() => {setShowGuideModal(false)}} />
    </>
  );
};

function mapStateToProps(state, ownProps){
  return {
    ...ownProps,
    selectedProject: state.common.selectedProject,
    selectedOrganisation: state.common.selectedOrganisation,
    organisations: state.common.organisations,
    user: state.user.current_user
  }
}

export default connect(mapStateToProps)(DashboardScreen);

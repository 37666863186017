import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    selectedProject: null,
    selectedOrganisation: null,
    organisations: []
  },
  reducers: {
    setSelectedProject: (state, action) => {
      return {
        ...state,
        selectedProject: action.payload,
      };
    },
    setSelectedOrganisation: (state, action) => {
      return {
        ...state,
        selectedOrganisation: action.payload,
      };
    },
    setOrganisations: (state, action) => {
      return {
        ...state,
        organisations: action.payload,
      };
    }
  },
});

export const {
  setSelectedProject,
  setSelectedOrganisation,
  setOrganisations
} = commonSlice.actions;

export default commonSlice.reducer;

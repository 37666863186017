import React, { useState, useEffect, useRef } from 'react';
import { TableContainer, Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, TextField, FormControl, Select, MenuItem } from '@mui/material';
import CustomCheckbox from "../Common/CustomCheckbox.jsx";

const getNewRow = () => {
  const newRow = { name: '', value: '', description: '', required: false, datatype: '' };
  return {...newRow};
}

const QueryParams = ({ initialParams=[], onParamsChange, skipOutsideClickCheck, hideExtraFields=false }) => {
  const formattedInitialParams = initialParams?.map(p => ({...getNewRow(), ...p}));
  const [newRows, setNewRows] = useState(formattedInitialParams ? formattedInitialParams : [getNewRow()]);
  const queryParamsRef = useRef(null);

  useEffect(() => {
    setNewRows([...(initialParams?.map(p => ({...getNewRow(), ...p})) || []), {...getNewRow()}]);
  }, [initialParams]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [newRows]);

  const handleRowInputChange = (index, field, value) => {
    let updatedRows = [...newRows];
    if (field === "required") {
      updatedRows[index] = { ...updatedRows[index], [field]: value ? true : false };
    } else {
      updatedRows[index] = { ...updatedRows[index], [field]: value };
    }

    updatedRows = updatedRows.filter((row) => {
      return row.name || row.value;
    });

    const newRow = getNewRow();
    updatedRows.push(newRow);
    setNewRows(updatedRows);
    if (skipOutsideClickCheck) {
      filterValidParams(updatedRows);
    }
  };

  const handleOutsideClick = async (event) => {
    if (queryParamsRef.current && !queryParamsRef.current.contains(event.target)) {
      filterValidParams(newRows);
    }
  };

  const filterValidParams = (updatedRows=[]) => {
    let filteredRows = updatedRows.filter((row) => {
      return row.name;
    });
    onParamsChange(filteredRows);
  }

  const rowTableCellSx = {
    padding: '5px 10px', margin: 0, fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "12px"
  }
  const inputTableCellSx = {
    padding: '5px 10px',
    fontSize: '12px',
    fontFamily: "Inter, Sans-serif",
    fontWeight: "450"
  }

  return (
    <Box id='boundary' ref={queryParamsRef}>
      <Typography variant="body1" sx={{fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "13px", mb: "4px"}}>Query Params</Typography>
      <TableContainer className='table-layout' sx={{ borderRadius: '5px' }}>
        <Table size='small' aria-label="simple" sx={{ border: 'none', padding: '0' }}>
          <TableHead>
            <TableRow sx={{ alignItems: 'center' }}>
              <TableCell sx={rowTableCellSx} key="key">Key</TableCell>
              <TableCell sx={rowTableCellSx} key="value">Value</TableCell>
              {
                !hideExtraFields && (
                  <TableCell sx={rowTableCellSx} key="description">Description</TableCell>
                )
              }
              {
                !hideExtraFields && (
                  <TableCell sx={rowTableCellSx} key="required">Required</TableCell>
                )
              }
              {
                !hideExtraFields && (
                  <TableCell sx={rowTableCellSx} key="datatype">Data Type</TableCell>
                )
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {newRows.map((row, index) => (
              <TableRow key={index} sx={{ border: 'none' }}>
                <TableCell sx={{ textAlign: 'center', padding: 0, margin: 0 }} key={`key_${index}`}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size='small'
                    placeholder='Key'
                    value={row.name}
                    onChange={e => handleRowInputChange(index, 'name', e.target.value)}
                    InputProps={{
                      classes: { notchedOutline: 'no-border' },
                      className: 'custom-textfield',
                    }}
                    sx={{
                      height: '27px',
                      '& .MuiInputBase-input': inputTableCellSx
                    }}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: 'center', padding: 0, margin: 0 }} key={`value_${index}`}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size='small'
                    placeholder='Value'
                    value={row.value}
                    onChange={e => handleRowInputChange(index, 'value', e.target.value)}
                    InputProps={{
                      classes: { notchedOutline: 'no-border' },
                      className: 'custom-textfield',
                    }}
                    sx={{
                      height: '27px',
                      '& .MuiInputBase-input': inputTableCellSx
                    }}
                  />
                </TableCell>
                {

                  !hideExtraFields &&
                  <TableCell sx={{ textAlign: 'center', padding: 0, margin: 0 }} key={`description_${index}`}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      size='small'
                      placeholder='Description'
                      value={row.description}
                      onChange={e => handleRowInputChange(index, 'description', e.target.value)}
                      InputProps={{
                        classes: { notchedOutline: 'no-border' },
                        className: 'custom-textfield',
                      }}
                      sx={{
                        height: '27px',
                        '& .MuiInputBase-input': inputTableCellSx
                      }}
                    />
                  </TableCell>
                }
                {
                  !hideExtraFields &&
                  <TableCell sx={{ textAlign: 'center', padding: 0, margin: 0 }} key={`required_${index}`}>
                    <CustomCheckbox
                      key={`required-${index}`}
                      checked={row.required}
                      label=""
                      onChange={(e) => handleRowInputChange(index, "required", e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </TableCell>
                }
                {
                  !hideExtraFields &&
                  <TableCell sx={{ textAlign: 'center', padding: 0, margin: 0 }} key={`datatype_${index}`}>
                    <FormControl fullWidth>
                      <Select
                        labelId="datatype-simple-select-label"
                        id="datatype-simple-select"
                        value={row.datatype}
                        label=""
                        size="small"
                        placeholder="datatype"
                        onChange={e => handleRowInputChange(index, 'datatype', e.target.value)}
                        sx={{...inputTableCellSx, py: 0, textTransform: "capitalize"}}
                      >
                        {["array", "object", "string", "number", "boolean", "null"].map((type) => {
                          return (
                            <MenuItem value={type} key={type}
                              sx={{fontFamily: "Inter, Sans-serif", textTransform: "capitalize"}}
                            >
                              {type}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </TableCell>
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default QueryParams;

import AppLayout from "../layouts";
import HomeScreen from "../components/Company/company";
import DashboardScreen from "../components/Dashboard/Dashboard";
import OrganisationScreen from "../components/Organisation";
import ProjectScreen from "../components/Project";
import LoginScreen from "../components/Login/LoginForm";
import SignUpForm from "../components/Signup/SignUpForm";
import EmailConfirmation from "../components/Account/EmailConfirmation";

const routes = [
  //////////////// Pre-auth routes ////////////////
  {
    path: "/",
    component: SignUpForm,
    layout: AppLayout,
    isPostAuth: false,
    exact: true,
  },
  {
    path: "/login",
    component: LoginScreen,
    layout: AppLayout,
    isPostAuth: false,
    exact: true,
  },
  {
    path: "/confirm-email",
    component: EmailConfirmation,
    layout: AppLayout,
    isPostAuth: false,
    exact: true,
  },

  //////////////// Post-auth routes ////////////////
  {
    path: "/home",
    component: HomeScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/dashboard",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/collections",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/create-organisation",
    component: OrganisationScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/organisation/:id/project",
    component: ProjectScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/collections/:collectionSlug",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/collections/:collectionSlug/:entitySlug",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/collections/:collectionSlug/folders/:folderSlug",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/collections/:collectionSlug/folders/:folderSlug/:entitySlug",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/members",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/test-scenarios/",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/test-scenarios/:testScenarioId/",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/test-scenarios/:testScenarioId/build",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/test-scenarios/:testScenarioId/build/:testScenarioBuildId",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/variable-set/",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/variable-set/:variableSetId/",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/test-suites/",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/test-suites/:testSuiteId/",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/test-plans/",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/test-plans/:testPlanId",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/test-suites/:testSuiteId/build/",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/test-suites/:testSuiteId/build/:testSuiteBuildId",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/test-plans/:testPlanId/build",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/test-plans/:testPlanId/build/:testPlanBuildId",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/build-reports/",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/js-functions/",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/data-sets/",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/data-sets/:dataSetId",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/settings",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/public-page",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/search/:chatId",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/search",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/faqs/",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
  {
    path: "/faqs/:faqSlug",
    component: DashboardScreen,
    layout: AppLayout,
    isPostAuth: true,
    exact: true,
  },
];

export default routes;
